<template>
	<div class="container-fluid h-100 my-2">
		<div class="row justify-content-center align-items-center h-100">
			<div class="col-auto">
				<div class="box" id="login">

					<div v-if="auto_connect || connecting" >
						<groomy-logo class="logo_center_bis"/>
						<p class="text-center message_chargement mb-0 mt-4">
							<font-awesome-icon class="spinner_home" :icon="['far', 'spinner']" pulse /><br/>{{ progressBarText }}
						</p>
					</div>

					<div v-else>
						<div class="text-center"><groomy-logo/></div>
						<p class="text-center mb-4 mt-4">{{ $t('global.app_description') }}</p>
						<form @submit.prevent="submitLogs">
							<div v-if="error_message" class="alert alert-warning">{{ error_message }}</div>
							<div class="form-group">
								<label>{{ $t('compte.email') }}</label>
								<input type="email" class="form-control" :placeholder="$t('compte.email')" id="mail_user" v-model="login">
							</div>
							<div class="form-group">
								<label>{{ $t("compte.password") }}</label>
								<input type="password" class="form-control" :placeholder="$t('compte.password')" id="password_user" v-model="password">
							</div>
							<button id="button_submit" type="submit" title="Connexion" class="btn btn-primary rounded-pill btn-block mt-4 mb-3" :class="{disable: verrouAutoConnect}">
								{{ $t('compte.connexion') }}
							</button>

							<!-- Si on a une connecition à internet, on peut proposer ces formulaires -->
							<router-link :to="{name: 'compteCreation'}"  :title="$t('compte.je_nai_pas_de_compte')" class="btn btn-link btn-block">{{ $t('compte.creer_compte') }} <font-awesome-icon :icon="['fal', 'angle-right']"/></router-link>
							<router-link :to="{name: 'resetPassword'}"  :title="$t('compte.j_ai_perdu_mdp')" class="btn btn-link btn-block">{{ $t('compte.mdp_oublie') }} <font-awesome-icon :icon="['fal', 'angle-right']"/></router-link>

							<div v-if="verrouAutoConnect">
								<p class="text-center"><font-awesome-icon v-if="verrouAutoConnect" :icon="['fal', 'spinner']" pulse/><br/>{{ $t('global.chargement') }} </p>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Constants from '../../config.local.js'
	import UserMixin from '@/mixins/User.js'
	import Tools from '@/mixins/Tools.js'
	import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "Home",
		mixins: [UserMixin, Tools, Navigation],
		components: {

		},
		data () {
			return {
				register_url: Constants.REGISTER_URL,
				reset_pwd_url: Constants.RESET_PWD_URL,
				login: '',
				password: '',
				connectivity: '',
				webservice_url: '',
				webservice_url_forgot_password: '',
				connecting: false,
				auto_connect: false,
				type_progress: '',
				error_message: ""
			}
		},
		mounted: async function() {
			// Si c'est le lancement de l'app, on affiche un message au démarrage de l'app
			if(!Constants.USER_ALREADY_LOGGED) {
				this.type_progress = "IAL"
				Constants.USER_ALREADY_LOGGED = true
			}
			this.init_component()
		},
		methods: {
			async submitLogs() {
				if(this.login != '' && this.password != '') {
					this.connecting = true
					try {
						await this.loginUser(this.login, this.password, this.$route.query.redirect)
					} catch (err) {
						this.error_message = err.message
                		this.returnToLogin()
					}
				}
			},
			returnToLogin() {
				this.auto_connect = false
				this.connecting = false
			},
			init_component() {
				if(this.$route.query.als && this.$route.query.mail) {
					this.login = this.$route.query.mail
	                this.auto_connect  = true
	                this.autolog_token = this.$route.query.als
	                
	                this.submitAutoLog(this.login, this.autolog_token)
	                    .then(() => {
	                    	this.$nextTick(function () {
	                    		window.location.href = '/#/cards'
	                    	})
	                    })
	                    .catch(() => {
	                        this.returnToLogin()
	                    })
	            }
			}
		},
		computed: {
			progressBarText: function() {
				if(this.type_progress) {
					return this.getTrad('progression_message.'+this.type_progress)
				}
				return ""
			},
			verrouAutoConnect: function() {
				return this.auto_connect || this.connecting
			},
			verrouManualConnect: function() {
				return this.connecting
			}
		}
	};
</script>
